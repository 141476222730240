/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  QueryStatus,
  axiosInstance,
  axiosInstanceWithPhoneToken,
} from "../../utils";
import {
  ActiveDriverByPhoneType,
  BindVehicleResponse,
  BindVehicleType,
  ReasonsRefusedEnum,
  StatusType,
  UpdateCardStatusType,
} from "./menuApi";
import { AxiosError } from "axios";

export interface MenuState {
  cardUpdatedStatus: QueryStatus;
  getCardStatus: QueryStatus;
  driverCanPayStatus: QueryStatus;
  lastTermsAndPolicyStatus: QueryStatus;
  status: StatusType | null;
  paymentRefusedReasons: ReasonsRefusedEnum[];
  bindVehicleStatus: QueryStatus;
  areLastTermsAndPolicyApproved: boolean;
  bindVehicleMessage: string;
  activeDriverByPhone: ActiveDriverByPhoneType;
  getActiveDriverByPhoneStatus: QueryStatus;
}

const initialState: MenuState = {
  cardUpdatedStatus: "idle",
  getCardStatus: "idle",
  driverCanPayStatus: "idle",
  lastTermsAndPolicyStatus: "idle",
  status: null,
  paymentRefusedReasons: [],
  bindVehicleStatus: "idle",
  areLastTermsAndPolicyApproved: false,
  bindVehicleMessage: "",
  activeDriverByPhone: {
    driver: null,
  },
  getActiveDriverByPhoneStatus: "idle",
};

export const bindVehicleAsync = createAsyncThunk(
  "bindVehicleAsync/call/",
  async (payload: BindVehicleType) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error(); //TODO
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    try {
      const response = await axios.put<BindVehicleResponse>(
        `/driver/bind_vehicle`,
        payload,
      );
      return response.data;
    } catch (e) {
      const errorMessages = [
        "driver not found",
        "driver blocked",
        "rotation_request_exceeded",
        "already_rejected_rotation_request",
      ];
      if (
        e instanceof AxiosError &&
        errorMessages.includes(e.response?.data.message as string)
      ) {
        return {
          message: e.response?.data.message as string,
        };
      } else {
        throw e;
      }
    }
  },
);

export const driverCanPayAsync = createAsyncThunk(
  "driverCanPay/call/",
  async (payload: { cardHolderIdentification: string }) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error();
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    try {
      const response = await axios.get<ReasonsRefusedEnum[]>(
        `/driver/can_pay/${payload.cardHolderIdentification}`,
      );
      return response.data;
    } catch (e) {
      if (
        e instanceof AxiosError &&
        (e.response?.data?.message === "UNAUTHORIZED" ||
          e.response?.data?.message === "NOT_FOUND")
      ) {
        return e.response?.data?.message as ReasonsRefusedEnum[];
      } else {
        throw e;
      }
    }
  },
);

export const updateCardStatusAsync = createAsyncThunk(
  "updateCardStatus/call/",
  async (payload: UpdateCardStatusType, thunkAPI) => {
    const axios = axiosInstance();
    await axios.post(
      `/driver_access/vehicles/${payload.cardHolderIdentification}/update_card_status`,
      { status: payload.status },
    );
    thunkAPI.dispatch(menuSlice.actions.resetPaymentRefusedReasons());
  },
);

export const getCardStatusAsync = createAsyncThunk(
  "getCardStatus/call/",
  async (payload: { cardHolderIdentification: string }) => {
    const axios = axiosInstance();
    const response = await axios.get<{ status: StatusType }>(
      `/driver_access/vehicles/${payload.cardHolderIdentification}/get_card_status`,
    );
    return response.data;
  },
);

export const lastTermsAndPolicyApprovedAsync = createAsyncThunk(
  "lastTermsAndPolicyApprovedAsync/call/",
  async (payload: { cardHolderIdentification: string }) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error();
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    const response = await axios.get<{
      areLastTermsAndPolicyApproved: boolean;
    }>(`/driver/last_terms_and_policy/${payload.cardHolderIdentification}`);
    return response.data.areLastTermsAndPolicyApproved;
  },
);

export const getActiveDriverByPhoneAsync = createAsyncThunk(
  "getActiveDriverByPhone/call/",
  async () => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error();
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    const response = await axios.get<ActiveDriverByPhoneType>(
      `/driver/active_by_phone`,
    );
    return response.data;
  },
);

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetPaymentRefusedReasons(state) {
      state.paymentRefusedReasons = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCardStatusAsync.pending, (state) => {
        state.cardUpdatedStatus = "processing";
      })
      .addCase(updateCardStatusAsync.fulfilled, (state) => {
        state.cardUpdatedStatus = "success";
      })
      .addCase(updateCardStatusAsync.rejected, (state) => {
        state.cardUpdatedStatus = "failed";
      })
      .addCase(getCardStatusAsync.pending, (state) => {
        state.getCardStatus = "processing";
      })
      .addCase(getCardStatusAsync.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.getCardStatus = "success";
        state.cardUpdatedStatus = "idle";
      })
      .addCase(getCardStatusAsync.rejected, (state) => {
        state.getCardStatus = "failed";
      })
      .addCase(driverCanPayAsync.pending, (state) => {
        state.driverCanPayStatus = "processing";
      })
      .addCase(driverCanPayAsync.fulfilled, (state, action) => {
        state.paymentRefusedReasons.push(...action.payload);
        state.driverCanPayStatus = "success";
      })
      .addCase(driverCanPayAsync.rejected, (state) => {
        state.driverCanPayStatus = "failed";
      })
      .addCase(bindVehicleAsync.pending, (state) => {
        state.bindVehicleStatus = "processing";
      })
      .addCase(bindVehicleAsync.fulfilled, (state, action) => {
        state.bindVehicleStatus = "success";
        if (action.payload.message === "rotation_request_exceeded") {
          state.paymentRefusedReasons.push("ROTATION_REQUEST_EXCEEDED");
        }
        if (action.payload.message === "already_rejected_rotation_request") {
          state.paymentRefusedReasons.push("ALREADY_REJECTED_ROTATION_REQUEST");
        }
        state.bindVehicleMessage = action.payload.message;
      })
      .addCase(bindVehicleAsync.rejected, (state) => {
        state.bindVehicleStatus = "failed";
      })
      .addCase(lastTermsAndPolicyApprovedAsync.pending, (state) => {
        state.lastTermsAndPolicyStatus = "processing";
      })
      .addCase(lastTermsAndPolicyApprovedAsync.fulfilled, (state, action) => {
        state.lastTermsAndPolicyStatus = "success";
        state.areLastTermsAndPolicyApproved = action.payload;
      })
      .addCase(lastTermsAndPolicyApprovedAsync.rejected, (state) => {
        state.lastTermsAndPolicyStatus = "failed";
      })
      .addCase(getActiveDriverByPhoneAsync.pending, (state) => {
        state.getActiveDriverByPhoneStatus = "processing";
      })
      .addCase(getActiveDriverByPhoneAsync.fulfilled, (state, action) => {
        state.getActiveDriverByPhoneStatus = "success";
        state.activeDriverByPhone = action.payload;
      })
      .addCase(getActiveDriverByPhoneAsync.rejected, (state) => {
        state.getActiveDriverByPhoneStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCardUpdatedStatus = (state: RootState) =>
  state.menu.cardUpdatedStatus;
export const selectGetCardStatus = (state: RootState) =>
  state.menu.getCardStatus;
export const selectCardStatus = (state: RootState) => state.menu.status;
export const selectDriverCanPayStatus = (state: RootState) =>
  state.menu.driverCanPayStatus;
export const selectDriverPaymentRefusedReasons = (state: RootState) =>
  state.menu.paymentRefusedReasons;
export const selectBindVehicleStatus = (state: RootState) =>
  state.menu.bindVehicleStatus;
export const selectAreLastTermsAndPolicyApproved = (state: RootState) =>
  state.menu.areLastTermsAndPolicyApproved;
export const selectLastTermsAndPolicyStatus = (state: RootState) =>
  state.menu.lastTermsAndPolicyStatus;
export const selectBindVehicleMessage = (state: RootState) =>
  state.menu.bindVehicleMessage;
export const selectActiveDriverByPhone = (state: RootState) =>
  state.menu.activeDriverByPhone;
export const selectActiveDriverByPhoneStatus = (state: RootState) =>
  state.menu.getActiveDriverByPhoneStatus;

export default menuSlice.reducer;
